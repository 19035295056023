import { useState } from "react"
import {useNavigate } from "react-router-dom";

import { useStateValue } from "../../state/context";
import { wollerIbanSave } from "../../api/apiCalls";

import Footer from "../../components/basics/Footer" 
import Lottie from "../../components/basics/Lottie"
import stripeImage from"../../assets/stripe.png"

import '../../styles/wollerData.scss'
// import axios from "axios"

export default function Dashboard(props){
    let navigate = useNavigate();
    const [{user}] = useStateValue();
    const [errorIbanRep,setErrorIbanRep] = useState("")
    const [showErrorIbanRep,setShowErrorIbanRep] = useState(false)
    const [iban,setIban] = useState()
    const [ibanRep,setIbanRep] = useState()
    const [isReadyToSave,setIsReadyToSave] = useState(false)
    const [isLoading ,setIsLoading] = useState(false)

    const handleIbanSave = ()=>{
        wollerIbanSave(user.stripeAccountId,iban).then(res=>{
            if(res.result.code === 200){
                navigate("/finish")
            }else{
                alert("Hubo un problema al almacenar tus datos bancarios, ponte en contacto con nuestro equipo de soporte")
                setIsLoading(false)
            }
        }).catch(err=>{
            console.error(err)
        })
    }
    const handleIbanRep =()=>{
        if(iban !== ibanRep){
            setErrorIbanRep("Los dos campos deben coincidir")
            return null
        }else if(iban.length>0 && ibanRep.length>0){
            setIsReadyToSave(true)
        }     
    }
    // const chekIban = ()=>{            
    //     // var headers = {
    //     //     'authority': 'ibanapi.com',
    //     //     'accept': '*/*',
    //     //     'accept-language': 'en-US,en;q=0.9,es-419;q=0.8,es;q=0.7',
    //     //     'content-length': '0',
    //     //     'cookie': '_ga=GA1.2.2079476695.1666286243; _gid=GA1.2.2043506858.1666286243; crisp-client%2Fsession%2Fa66df6b7-80a2-4413-8df3-788100f005fa=session_fd184d81-7c9e-4e01-a0a0-9dea2eb308fe; XSRF-TOKEN=eyJpdiI6IlhVaytHYnpzWEVHK2ZxTG9TM01DL2c9PSIsInZhbHVlIjoiSHRoUmhXUnRjWk9WOHhLa2wxT0U1V0IreFVocStqRFdyeitZdjFPQUk4K2duODlDaVQ4SnVKREV1N2RyZEN6Y1YvMTk2bmFWWDlGSkppZFQ4b2MxQ29TUDMzYXgrZjg1TDhKbWFmQjF0MDV5THo1dGtaVmRCSjR1MkUzS2UyUE8iLCJtYWMiOiJhZTk4YzEyYWNjMzVmOGQzZjk1MGRmNWNkMDQ4Yjk3NDFjYWQyZDE0MzQzMDkyMmQ1NWYyYTEzZWYyMDZkOTdhIiwidGFnIjoiIn0%3D; ibanapi_session=eyJpdiI6IjBGZHNIMjRJQUxkaXc2THpXZmxpR0E9PSIsInZhbHVlIjoidmVjZEROZzVqV2wycmVHQ1owQ3NTcVllem9zSkhQZkJjZXU5ZGxlemE1aHJicnVUdHFzOGQwQW1VUWFuaEZFS3lKd3JkUTNROFJ4dUlqK3d0dktHUUpiMGN4S1FIaTh3U3Q0dXgrQ202U2J5S3NocktNRjMrQ0U2bWhoSjFjRWEiLCJtYWMiOiJiNmQ0MWIzNmNiNDI5ZDlmN2I4Mzg5NTA2ZTNmMzZkMGJjZmY0Zjc0ZDM4MWI3NWVjZmE2NWE3ODQzYWQ5MTJiIiwidGFnIjoiIn0%3D',
    //     //     'origin': 'https://ibanapi.com',
    //     //     'referer': 'https://ibanapi.com/iban-checker',
    //     //     'sec-ch-ua': '"Chromium";v="106", "Google Chrome";v="106", "Not;A=Brand";v="99"',
    //     //     'sec-ch-ua-mobile': '?0',
    //     //     'sec-ch-ua-platform': '"Windows"',
    //     //     'sec-fetch-dest': 'empty',
    //     //     'sec-fetch-mode': 'cors',
    //     //     'sec-fetch-site': 'same-origin',
    //     //     'user-agent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/106.0.0.0 Safari/537.36',
    //     //     'x-csrf-token': 'kAWAJWqiQDUA2t6LL7hVwSe6xCOhXPO5bzZw4Tdj',
    //     //     'x-requested-with': 'XMLHttpRequest'
    //     // };
        
      
    //     // let url = `https://ibanapi.com/validate-iban/${iban}`
    //     // console.log("🚀 ~ file: WollerDataPage.jsx ~ line 39 ~ chekIban ~ url", url)

        
    //     // axios.post(url,headers)
    //     // .then(response => {
    //     //     console.log("🚀 ~ file: WollerDataPage.jsx ~ line 43 ~ chekIban ~ response", response)
        
            
    //     // }).catch(err => {
    //     //     console.log("🚀 ~ file: WollerDataPage.jsx ~ line 47 ~ chekIban ~ err", err)
    //     //     setShowErrorIban(true)
    //     //     setErrorIban("Debes introducir un IBAN valido")
    //     // })
        
    // }
    return(
        <div className="wollerData mainContainer">
            <h1>Necesitamos que nos proporciones algunos datos.</h1>
                <div className="card verticalCenterContainer">
                    <h4>Te recordamos que tus datos estarán protegidos en todo momento.</h4>
                    <label className="ibanInput">
                        Introduce tu IBAN
                        <input type="text" onChange={(event)=>{setIban(event.target.value); }}  value={iban} name="iban" id="iban" />
                    </label>
                    <label className="ibanInput">
                        Introduce nuevamente tu IBAN
                        <input type="text" onChange={(event)=>{setIbanRep(event.target.value);setShowErrorIbanRep(false)}} onBlur={()=>handleIbanRep()} value={ibanRep} name="iban" id="ibanRep" />
                        {showErrorIbanRep && (<span className="errorText">{errorIbanRep}</span>)}
                    </label>
                    <div className="imagesContainer">
                        <img alt="stripe"  className="stripeImage" src={stripeImage}></img>
                    </div>
                    <button disabled={!isReadyToSave} className="button buttonYellow" onClick={()=>{setIsLoading(true);handleIbanSave()}}>
                        { isLoading ?( <Lottie style={{height: 27, width: 77}} />):
                           "Enviar"}</button>
                </div>
            <Footer className="footer" color="black"/>
        </div>
    )

}