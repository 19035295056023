import React, { useEffect, useState } from 'react';
import { useStateValue } from "../../state/context";
import { Modal } from "react-responsive-modal";

import '../../styles/uploadPhotosPage.scss';

export default function UploadPhotosPrice() {

    const [{price},dispatch] = useStateValue();

    const [newPrice ,setNewPrice] = useState(price);
    const [open ,setOpen] = useState(false);
    const [newExtras ,setNewExtras] = useState([
        {
            "name": "Precio original",
            "units": 1,
            "pricePerUnit": price
        }
    ]);
    const [inputPrice, setInputPrice] = useState(0);
    const [inputName, setInputName] = useState("");

    const onChangeHandlerInputPrice = event => {
        setInputPrice(parseFloat(event.target.value));
    };

    const onChangeHandlerInputName = event => {
    setInputName(event.target.value);
    };

    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    const addExtra = () => {
        setNewExtras( old => [...old, {
            "name": inputName,
            "units": 1,
            "pricePerUnit": inputPrice
        }])

        setNewPrice(newPrice + inputPrice)
        setInputPrice(0)
        setInputName("")
    }

    const onContinue = (next) => {
        if(next){
            dispatch({
                type: "SET_UPLOAD_PHOTOS_PRICE",
                price: newPrice,
            });

            dispatch({
                type: "SET_UPLOAD_PHOTOS_EXTRAS",
                extras: newExtras,
            });
        }

        let url = window.location.href.split('?');
        window.location.replace(`${url[0].replace("price","signature")}?${url[1]}?${url[2]}`);
    }

    return (
        <div className='upload-photos-container'>
            <div className='upload-photos-head'>
                <div className='f-row'>
                    <p className='upload-photos-step'>3/5</p>
                    <p className='upload-photos-step-title'>Añadir extras</p>
                </div>
                <p className='upload-photos-head-p'>Añade los extras necesarios en caso de haber prestado mas servicios.</p>
                <div className='f-row'>
                    <div className='upload-photos-line-bold w-100'></div>
                </div>
                </div>
                <div className='upload-photos-carrousel'>
                    <div>
                        <p className='upload-photos-price-total'>Precio total: { (newPrice * 1.21).toFixed(2) } €</p>
                        <p className='upload-photos-price-semi'>Precio sin IVA: {newPrice.toFixed(2)} €</p>
                        <button className='upload-photos-price-button' onClick={onOpenModal}>Ver resumen</button>
                    </div>
                    <p className='upload-photos-price-add'>Añadir Nuevo extra</p>
                    <div className='upload-photos-row'>
                        <p>Nombre:</p>
                        <input className='upload-photos-price-input ml-20' onChange={onChangeHandlerInputName} value={inputName}></input>
                    </div>
                    <div className='upload-photos-row'>
                        <p>Precio (sin IVA):</p>
                        <input type="number" className='upload-photos-price-input ml-25' onChange={onChangeHandlerInputPrice} value={inputPrice}></input>
                    </div>
                    <button className='upload-photos-price-button' onClick={addExtra}>Añadir</button>
                </div>
                <div className='upload-photos-button-wrapper'>
                    <button className='upload-photos-button-clear' onClick={() => onContinue(false)}>Omitir</button>
                    <button className='upload-photos-button-signature' onClick={() => onContinue(true)}>Siguiente</button>
                </div>
                <Modal open={open} onClose={onCloseModal} center>
                    <div className="upload-photo-modal-wrapper">
                    <p className='upload-photo-modal-price-title'>Desglose del precio total:</p>
                    {
                        newExtras.map( extra => (
                            <p>{`- ${extra.name} : ${(extra.pricePerUnit * 1.21).toFixed(2)} €`}</p>
                        ))
                    }
                    </div>
                </Modal>
            </div>
        )
}
