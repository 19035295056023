import React, { useState, useEffect } from 'react';

import {getJobDetailpublicData} from '../../api/apiCalls';

import client from "../../assets/upload-photos/client2.png";
import ping from "../../assets/upload-photos/ping.png";
import phone from "../../assets/upload-photos/phone.png";
import casco from "../../assets/upload-photos/casco.png";

import "../../styles/jobDetail.scss";

export default function JobDetail() {

    const [jobData, setJobData] = useState(undefined);

    useEffect(()=> {
        getNewData();
    },[]);

    const getNewData = async () => {
        let url = window.location.href.split("?");

        const res =  await getJobDetailpublicData(url[1]);
        setJobData(res.result);
    }

    return (
        <div className='job-detail'>
            <img src={casco} alt="isotipo"/>
            <p>SERVICIO WOLLY</p>

            {
                jobData && (
                    <div>
                        <div>
                            <p className='border blue'>{jobData.jobType == "lead" ? "MARKETPLACE": "PRECIO CERRADO"}</p>
                            <p className='blue uppercase mt'>{jobData.verticalName}</p>
                        </div>
                        <p className='title'>{jobData.title}</p>
                        <div>
                            <img src={client}/>
                            <p>{jobData.name}</p>
                        </div>

                        <div>
                            <img src={ping}/>
                            <p>{jobData.address}</p>
                        </div>

                        <div>
                            <img src={phone}/>
                            <p>{jobData.phone}</p>
                        </div>
                        <p>Descripción:</p>
                        <p>{jobData.description}</p>
                    </div>
                )
            }
            <p className='contact'>Si tienes dudas contacta con nosotros llamando al número 911086067 </p>
        </div>
    )
}
