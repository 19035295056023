import { useState, useEffect } from 'react';
import { findJobByIDs, setBudgetData, setDelayBudgetReply } from "../../api/apiCalls";

import maletin from "../../assets/upload-photos/maletin.png";
import phone from "../../assets/upload-photos/phone.png";
import ping from "../../assets/upload-photos/ping.png";
import client from "../../assets/upload-photos/client2.png";
import wolly from "../../assets/upload-photos/wolly-yellow.png";

import '../../styles/responseBudgetData.scss';

export default function ResponseBudgetData(){

    // conseguir datos en base a jobid y wollerId
    const [matchData, setMatchData] = useState(false);
    const [showPrice, setShowPrice] = useState(false);
    const [val, setVal] = useState("");

    useEffect(()=> {
        setNewData();
    },[]);

    const setNewData = async () => {
        let url = window.location.href.split('?');
        findJobByIDs(url[1], "lead").then(res=>{  // jobId, jobType
            console.log(res.result.result)
            setMatchData(res.result.result);
        }).catch(err=>{
            console.error(err);
        })
    }

    const estimated = async (omit) => {
        let url = window.location.href.split('?');
        
        setBudgetData(url[1], "estimated", url[2], omit ? parseInt(val) : null).then( res => {  // jobId, state, wollerId
            console.log(res)
        }).catch(err=>{
            console.error(err);
        })

        window.location.replace(`${url[0].replace("response-budget-data","response-budget-data-finish")}?${url[1]}?${url[2]}`);
    }

    const desestimated = async () => {
        let url = window.location.href.split('?');
        
        setBudgetData(url[1], "desestimated", url[2], false).then( res => {  // jobId, state, wollerId
            console.log(res)
        }).catch(err=>{
            console.error(err);
        })

        window.location.replace(`${url[0].replace("response-budget-data","response-budget-data-finish")}?${url[1]}?${url[2]}`);
    }

    const delayBudgetReply = async () => {
        let url = window.location.href.split('?');
        
        setDelayBudgetReply(url[1], new Date(), url[2], false).then( res => {  // jobId, state, wollerId
            console.log(res)
        }).catch(err=>{
            console.error(err);
        })

        window.location.replace(`${url[0].replace("response-budget-data","response-budget-data-finish")}?${url[1]}?${url[2]}`);
    }

    return (
        <div className="response-budget-data-page mainContainer">
            <img src={wolly} alt="isotipo" className="upload-photo-main-image" />
            <p className='response-budget-data-main-title'>servicio wolly</p>
            <p className='upload-photo-main-subtitle'>¡Cargaremos 1€ de saldo solo por informarnos!</p>
            <div className='upload-photo-main-card'>
                <div className='f-row'>
                    <p className='upload-photo-main-fixed-price'>LEAD</p>
                    <p className='upload-photo-main-fixed-gremio'>{matchData.title}</p>
                </div>
                <div className='f-row'>
                    <img src={client} alt="isotipo" className="upload-photo-main-card-icon" />
                    <p className='upload-photo-main-card-p'>{matchData.name}</p>
                </div>
                <div className='f-row'>
                    <img src={ping} alt="isotipo" className="upload-photo-main-card-icon" />
                    <p className='upload-photo-main-card-p'>{matchData.address}</p>
                </div>
                <div className='f-row'>
                    <img src={phone} alt="isotipo" className="upload-photo-main-card-icon" />
                    <p className='upload-photo-main-card-p'>{matchData.phone}</p>
                </div>
                <div className='f-row'>
                    <img src={maletin} alt="isotipo" className="upload-photo-main-card-icon" />
                    <p className='upload-photo-main-card-p'>{matchData.type}</p>
                </div>
            </div>

            {!showPrice && (
                <>
                    <p className='upload-photo-main-subtitle'>Hola Woller, por favor, informa si has realizado o vas a realizar el servicio</p>
                    <button className='response-budget-data-accept' onClick={() => setShowPrice(true)}>Sí, presupuesto aceptado</button>
                    <button className='response-budget-data-negate' onClick={() => desestimated()}>No han aceptado mi presupuesto</button>
                    <button className='response-budget-data-no-confirm' onClick={() => delayBudgetReply()}>Sin confirmación del cliente</button>
                </>
            )}
            {showPrice && (
                <>
                    <p className='upload-photo-main-subtitle'>Cuanto a sido el importe en €:</p>
                    <input
                        type="number"
                        pattern="[0-9]*"
                        value={val}
                        onChange={(e) =>
                        setVal((v) => (e.target.validity.valid ? e.target.value : v))}
                        className='response-budget-data-price'
                    />
                    <div className='f-row f-jc-center'>
                        <button className='response-budget-data-price-submit' onClick={() => estimated(true)}>Enviar</button>
                        <button className='response-budget-data-price-omit' onClick={() => estimated(false)}>Omitir</button>
                    </div>
                </>
            )}

        </div>
    )
}
