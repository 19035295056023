import { useState } from 'react';
import '../../styles/uploadPhotosPage.scss';
import { useStateValue } from "../../state/context";

export default function UploadPhotosPre(){

    const [photos ,setPhotos] = useState([]);
    const [{user, price},dispatch] = useStateValue();
   
    const handlePhotos=async (files,index) => {
        let url = window.location.href.split('?');

        files = [...files]
        if(files.length > 1 ){
            let images = files.map(async (file)=>{
                return  getFileData(file)
           })
           Promise.all(images).then(results => {
                let photosParsed = results.map((photo,index)=>{
                    let fileName = url[1] + Math.floor(Math.random() * 90000) + 10000;
                    if(photo){
                        return {base64:photo,fileName:fileName};
                    }else{
                        return null
                    }
                })
                setPhotos(searches => searches.concat(photosParsed))
           })
       } else if(files.length === 1){
            let image =  await getFileData(files[0])
            let fileName = url[1] + Math.floor(Math.random() * 90000) + 10000;
            if(index!== undefined){
                let newphoto = [...photos]
                newphoto[index] = {base64:image,fileName:fileName}
                setPhotos(newphoto)
            }else{
                setPhotos([...photos, {base64:image,fileName:fileName}])
            }
        }
    }

    const getFileData = (file) => {      
        return new Promise((resolve, reject) => {
            if (window.FileReader) {
                // great success! All the File APIs are supported.
                let reader = new FileReader();
            
                reader.onloadstart = () => {};
                reader.onloadend = e => {
                    // starting the image optmization process
                    let image = new Image();
                    image.onload = () => {
                        // Resize the image
                        let canvas = document.createElement("canvas"),
                            max_size = 550,
                            width = image.width,
                            height = image.height;
            
                        if (width > height) {
                        if (width > max_size) {
                            height *= max_size / width;
                            width = max_size;
                        }
                        } else {
                        if (height > max_size) {
                            width *= max_size / height;
                            height = max_size;
                        }
                        }
            
                        canvas.width = width;
                        canvas.height = height;
                        canvas.getContext("2d").drawImage(image, 0, 0, width, height); // this is the base64 img
            
                        let base64 = canvas.toDataURL("image/jpeg");
                        resolve(base64);
                    };
            
                    image.src = e.target.result;
                }
                reader.readAsDataURL(file);
            }; // read in the image file as a data URL.
            
        });
    };

	
    const dispatchPhotos = () => {
        dispatch({
            type: "SET_PREIMAGE_DATA",
            preImageData: photos,
        });

        let url = window.location.href.split('?');
        window.location.replace(`${url[0].replace("pre","post")}?${url[1]}?${url[2]}`);
    }

    return(
        <div className='upload-photos-container'>
            <div className='upload-photos-head'>
                <div className='f-row'>
                    <p className='upload-photos-step'>1/{price ? '5' : '4'}</p>
                    <p className='upload-photos-step-title'>Fotos del antes</p>
                </div>
                <p className='upload-photos-head-p'>Realiza fotografias de las zonas en las que vayas a intervenir. Asegurate que se ven con claridad y nitidez.</p>
                <div className='f-row'>
                    <div className='upload-photos-line-bold w-50'></div>
                    <div className='upload-photos-line-thing w-50'></div>
                </div>
            </div>
            <div className='upload-photos-carrousel'>
                <label htmlFor="photo" className="upload-photos-carrousel-add">
                    <span>Añadir fotos</span>
                </label>
                <input type="file"  id="photo" multiple  hidden onChange={(event)=>handlePhotos(event.target.files)}/>
                <div className="photosContainer">
                    {photos.length > 0  && photos[0] !== null ? photos.map((photo,index)=>{
                        if(photo != null){
                            return <img key={photo.base64? photo.base64:photo.name()} className='photosContainer-photo' src={photo.base64? photo.base64:photo.name()} alt="" />
                        }
                    }):
                        <p></p>
                    }
                </div>
            </div>
            <div className='upload-photos-button-wrapper'>
                <button disabled={photos.length === 0} onClick={dispatchPhotos} className='upload-photos-button'>Continuar</button>    
            </div>
        </div>
    )   
}