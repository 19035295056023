import { fetchFromAPI } from "../config/api";

// PAYMENTS


export const createPaymentLinkLead = async (jobId,wollerId,price,review) => {
  console.log(jobId,wollerId,price)
    const res = await fetchFromAPI("createPaymentLinkLead", {jobId:jobId,wollerId:wollerId,price:price,review:review});
    return res;
}


// JOBS 

export const getJobsFromUser = async (userId) => {
    const res = await fetchFromAPI("getJobsFromUser", {id: userId});
    return res;
}
export const getJobById = async (jobId) => {
    const res = await fetchFromAPI("getJobByIdWollyPay", {id: jobId});
    return res;
}

//User
export const authUser = async (token) =>{
    try {
      return {
        status: "ok",
        result: await await fetchFromAPI("authUser", {token:token})
      };
    } catch (error) {
      return {
        status: "error",
        result: error
      };
    }
}
export const authJober = async (token) =>{
    try {
      return {
        status: "ok",
        result: await await fetchFromAPI("authJober", {token:token})
      };
    } catch (error) {
      return {
        status: "error",
        result: error
      };
    }
}

export const acceptTerms = async () =>{
    try {
      return {
        status: "ok",
        result: await await fetchFromAPI("acceptTerms")
      };
    } catch (error) {
      return {
        status: "error",
        result: error
      };
    }
}
export const wollerIbanSave = async (accountId,iban) =>{
    try {
      return {
        status: "ok",
        result: await await fetchFromAPI("wollerIbanSave",{accountId:accountId,iban:iban})
      };
    } catch (error) {
      return {
        status: "error",
        result: error
      };
    }
}

// MATCH

export const getUploadPhotosData = async (jobId, wollerId) => {
  try {
    return {
      status: "ok",
      result: await await fetchFromAPI("getUploadPhotosData",{jobId: jobId, wollerId: wollerId})
    };
  } catch (error) {
    return {
      status: "error",
      result: error
    };
  }
}

export const setNotificationsPhotos = async (jobId, photos, extras, price) => {
  try {
    return {
      status: "ok",
      result: await await fetchFromAPI("setNotificationsPhotos",{jobId: jobId, photos: photos, extras: extras, price: price})
    };
  } catch (error) {
    return {
      status: "error",
      result: error
    };
  }
}
//APPOINTMENT
export const setAppontment = async ({jobId, appointment,  wollerId}) => {
  try {
    return {
      status: "ok",
      result: await fetchFromAPI("changeFixedAppointment",{jobId: jobId, date:appointment, joberId: wollerId ,selectedSchedule:undefined})
    };
  } catch (error) {
    return {
      status: "error",
      result: error
    };
  }
}

// Response Budget

export const findJobByIDs = async (id, jobType) => {
  try {
    return {
      status: "ok",
      result: await fetchFromAPI("findJobByIDs",{id: id, jobType: jobType})
    };
  } catch (error) {
    return {
      status: "error",
      result: error
    };
  }
}

export const setBudgetData = async (jobId, state, userId, wollerBudget) => {
  try {
    return {
      status: "ok",
      result: await fetchFromAPI("setBudgetData",{jobId: jobId, state: state, userId: userId, origin: "Vía JustNow", coments: "Vía JustNow", wollerBudget: wollerBudget})

    };
  } catch (error) {
    return {
      status: "error",
      result: error
    };
  }
}

export const setDelayBudgetReply = async (jobId, delayBudgetReply, userId) => {
  try {
    return {
      status: "ok",
      result: await fetchFromAPI("setDelayBudgetReply",{jobId: jobId, delayBudgetReply: delayBudgetReply, userId: userId})

    };
  } catch (error) {
    return {
      status: "error",
      result: error
    };
  }
}

// JOB DETAIL

export const getJobDetailpublicData = async (jobId) => {
  try {
    return {
      status: "ok",
      result: await fetchFromAPI("getJobDetailpublicData", {jobId: jobId} )
    };
  } catch (error) {
    return {
      status: "error",
      result: error
    };
  }
}