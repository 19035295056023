import { useRef, useState } from 'react';
import '../../styles/uploadPhotosPage.scss';
import { useStateValue } from "../../state/context";
import SignaturePad from "react-signature-canvas";
import { setNotificationsPhotos } from "../../api/apiCalls";


export default function UploadPhotosPost(){

    const [{preImageData, postImageData, signature, extras, price},dispatch] = useStateValue();
    const sigCanvas = useRef({});
    const [disabled , setDisabled] = useState(false);


    const clear = () => sigCanvas.current.clear();
    
    const dispatchSignature = () => {
        setDisabled(true);

        let url = window.location.href.split('?');
        let date = new Date();

        const photos = {
            preImages: preImageData,
            postImages: postImageData,
            signature: signature,
            signaturePro: sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"),
            date: date
        }

        setNotificationsPhotos(url[1], photos, extras, price).then(res=>{  // jobId, photos
            window.location.replace(`${url[0].replace("signature-pro","finish")}?${url[1]}?${url[2]}`);
            setDisabled(false);
        }).catch(err=>{
            alert(err);
        })
    }

    return(
        <div className='upload-photos-container'>
            <div className='upload-photos-head'>
                <div className='f-row'>
                    <p className='upload-photos-step'>{price ? '5' : '4'}/{price ? '5' : '4'}</p>
                    <p className='upload-photos-step-title'>Firma del profesional</p>
                </div>
                <p className='upload-photos-head-p'>Firma en la pantalla en simbolo de conformidad con el servicio prestado.</p>
                <div className='f-row'>
                    <div className='upload-photos-line-bold w-100'></div>
                </div>
            </div>
            <div className='upload-photos-carrousel'>
                <SignaturePad
                    ref={sigCanvas}
                    canvasProps={{
                        className: "signatureCanvas"
                    }}
                />
            </div>
            <div className='upload-photos-button-wrapper'>
                <button className='upload-photos-button-clear' onClick={clear}>Limpiar</button>
                <button className='upload-photos-button-signature' disabled={disabled} onClick={dispatchSignature}>Firmar y finalizar</button>
            </div>
        </div>
    )   
}