import { useEffect, useState } from "react";
import { authJober } from "../api/apiCalls";
import { useStateValue } from "../state/context";
import { useSearchParams,useNavigate } from "react-router-dom";
import '../styles/dashboard.scss'
import payLogo from "../assets/wollyPayLogo.png"
import Footer from "../components/basics/Footer" 

export default function Dashboard(props){
    let navigate = useNavigate();
    const [{user},dispatch] = useStateValue();
    const [searchParams] = useSearchParams();
    const [token] = useState(searchParams.get("token")) 
    const [loadReady,setLoadReady] = useState(false)
    
    useEffect(()=>{
         authHandler()
    },[props])

    const authHandler  = async ()=>{
        if(token){
            authJober(token).then(res=>{
                if(res.result){
                    window.localStorage.setItem("token",token)
                    dispatch({
                        type: "SET_USER_DATA",
                        id:res.result.id,
                        name:res.result.get("Name"),
                        phone:res.result.get("Phone"),
                        email:res.result.get("email"),
                        stripeAccountId:res.result.get("stripeAccountId")
                    });
                    setLoadReady(true)
                }else{
                    navigate("/error")
                }
            })
        }else{
            navigate("/error")
        }
    }
    return(
        <div className={ "dashboard mainContainer "}>
            <div className="titleContainer">
                <img src={payLogo} alt="WollyPay Logo"  />
                <h1 className="title">Pago seguro con Wolly Pay</h1>
                <p className="subTitle">Con Wolly Pay podras recibir el pago de los clientes de forma segura:</p>
            </div>
            <div className="stepsContainer">
                <p className="step">Para que puedas recibir los pagos que te realizan tus clientes, necesitamos que nos facilites algunos datos.</p>
                <p className="step">Tus datos se almacenaran de forma segura.</p>
                <p className="step">Este proceso solo debe realizarse una única vez.</p>
            </div>
            <button className="button buttonYellow" disabled={!loadReady} onClick={()=>{navigate("/wollerData")}}>Continuar</button>
            <Footer className="dashboardFooter" color="black"/>
        </div>
    )

}