
import React from "react";
import { Routes, Route,HashRouter } from "react-router-dom";
import { alteredState } from "./state/alteredState";
import { reducer } from "./state/reducer";
import { StateProvider } from "./state/context";

import Dashboard from "./pages/Dashboard"
import Finish from "./pages/finish/FinishPage"
import Error from "./pages/error/ErrorPage"
import WollerData from "./pages/wollerData/WollerDataPage"
import UploadPhotos from "./pages/upload-photos/UploadPhotosPage"
import UploadPhotosPre from "./pages/upload-photos/UploadPhotosPre"
import UploadPhotosPost from "./pages/upload-photos/UploadPhotosPost";
import UploadPhotosSignature from "./pages/upload-photos/UploadPhotosSignature";
import UploadPhotosSignaturePro from "./pages/upload-photos/UploadPhotosSignaturePro";
import UploadPhotosFinish from "./pages/upload-photos/UploadPhotosFinish";
import UploadPhotosPrice from "./pages/upload-photos/UploadPhotosPrice";
import ResponseBudgetData from "./pages/woller-budget/ResponseBudgetData";
import ResponseBudgetDataFinish from "./pages/woller-budget/ResponseBudgetDataFinish";
import JobDetail from "./pages/job-detail/JobDetail"

const App = () => {

    return (
      <HashRouter>
        <StateProvider initialState={alteredState} reducer={reducer}>
            <Routes>
                <Route index  path="/" element={<Dashboard/>} />
                <Route path="wollerData" element={<WollerData/>} />
                <Route path="error" element={<Error/>} />
                <Route path="finish" element={<Finish/>} />
                <Route path="upload-photos" element={<UploadPhotos/>} />
                <Route path="upload-photos-price" element={<UploadPhotosPrice/>} />
                <Route path="upload-photos-pre" element={<UploadPhotosPre/>} />
                <Route path="upload-photos-post" element={<UploadPhotosPost/>} />
                <Route path="upload-photos-signature" element={<UploadPhotosSignature/>} />
                <Route path="upload-photos-signature-pro" element={<UploadPhotosSignaturePro/>} />
                <Route path="upload-photos-finish" element={<UploadPhotosFinish/>} />
                <Route path="response-budget-data" element={<ResponseBudgetData/>} />
                <Route path="response-budget-data-finish" element={<ResponseBudgetDataFinish/>} />
                <Route path="job-detail" element={<JobDetail/>} />
            </Routes>
        </StateProvider>
      </HashRouter>
    );
};

export default App;
