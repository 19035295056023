
export const initialState = {
   
    user:{
        id:null,
        name:null,
        phone:null,
        location:null,
        email:null,
        stripeAccountId:null
    }, 
    preImageData: null,
    postImageData: null,
    signature: null,
    price: null,
    extras: null
};
