import '../../styles/uploadPhotosPage.scss';
import hand from "../../assets/upload-photos/hand.png";
import wolly from "../../assets/upload-photos/wolly-logo-billing.png";


export default function UploadPhotosFinish(){

    return (
        <div className='upload-photos-finish-wrapper'>
            <img src={hand} alt="isotipo" className="upload-photos-finish-main-image" />
            <p className='upload-photos-finish-title'>¡Ya tenemos todo!</p>
            <p>Gracias por haber hecho un buen servicio</p>
            <p>Seguimos...</p>
            <p className='m-top-120'>Wolly Forever</p>
            <img src={wolly} alt="isotipo" className="upload-photo-finish-wolly-image" />
        </div>
    )
}