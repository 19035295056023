import { useRef, useState } from 'react';
import '../../styles/uploadPhotosPage.scss';
import { useStateValue } from "../../state/context";
import SignaturePad from "react-signature-canvas";

export default function UploadPhotosPost(){

    const [{preImageData, postImageData, price},dispatch] = useStateValue();
    const sigCanvas = useRef({});

    const clear = () => sigCanvas.current.clear();
    
    const dispatchSignature = () => {
        dispatch({
            type: "SET_SIGNATURE_DATA",
            signature: sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"),
        });

        let url = window.location.href.split('?');
        window.location.replace(`${url[0].replace("signature","signature-pro")}?${url[1]}?${url[2]}`);
    }

    return(
        <div className='upload-photos-container'>
            <div className='upload-photos-head'>
                <div className='f-row'>
                    <p className='upload-photos-step'>{price ? '4' : '3'}/{price ? '5' : '4'}</p>
                    <p className='upload-photos-step-title'>Firma del cliente</p>
                </div>
                <p className='upload-photos-head-p'>Pide al cliente que firme en la pantalla en símbolo de conformidad con el servicio prestado.</p>
                <div className='f-row'>
                    <div className='upload-photos-line-bold w-100'></div>
                </div>
            </div>
            <div className='upload-photos-carrousel'>
                { price && (
                    <p className='upload-photos-signature-price'>El precio total del servicio asciende a {(price * 1.21).toFixed(2)}€ IVA incluido. Este importe se le cobrará en la tarjeta facilitada.</p>
                )}
                <SignaturePad
                    ref={sigCanvas}
                    canvasProps={{
                        className: "signatureCanvas"
                    }}
                />
            </div>
            <div className='upload-photos-button-wrapper'>
                <button className='upload-photos-button-clear' onClick={clear}>Limpiar</button>
                <button className='upload-photos-button-signature' onClick={dispatchSignature}>Firmar</button>
            </div>
        </div>
    )   
}