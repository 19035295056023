
import '../../styles/error.scss'
import payLogo from "../../assets/wollyPayLogo.png"
import Footer from "../../components/basics/Footer"

export default function Error(props){
      
    return(
        <div className="error mainContainer">
            <div className="titleContainer">
                <img src={payLogo} alt="WollyPay Logo"  />
                <h1 className="title">Wolly Pay</h1>
            </div>
            <div className="stepsContainer">
                <p className="step">Este enlace es invalido, si necesitas completar tu informacion ponte en contacto con nuestro equipo de soporte para que te proporcionen un enlace valido.</p>
            </div>
            <Footer color={"black"}/>
        </div>
    )

}