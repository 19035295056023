
export const reducer = (state, action) => {
    switch (action.type) {
        case "SET_USER_DATA":
            return {
                ...state,
                user:{
                    id:action.id,
                    name:action.name,
                    phone:action.phone,
                    location:action.location,
                    email:action.email,
                    stripeAccountId:action.stripeAccountId
                }
            };
        case "SET_PREIMAGE_DATA":
            return {
                ...state,
                preImageData: action.preImageData
            };
        case "SET_POSTIMAGE_DATA":
            return {
                ...state,
                postImageData: action.postImageData
            };
        case "SET_SIGNATURE_DATA":
            return {
                ...state,
                signature: action.signature
            };
        case "SET_UPLOAD_PHOTOS_PRICE":
            return {
                ...state,
                price: action.price
            };
        case "SET_UPLOAD_PHOTOS_EXTRAS":
            return {
                ...state,
                extras: action.extras
            };
        default:
            return state;
    }
};
