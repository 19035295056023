
import '../../styles/finishPage.scss'
import serviceOwned from "../../assets/serviceOwnedIcon.png"

export default function FinishPage(){
   
    return(
        <div className="finish mainContainer">
            <div className="titleContainer">
                <img src={serviceOwned} alt="finish"  />
                <h1 className="title">¡Hemos recibido tus datos correctamente!</h1>
            </div>
            <div className='infoContainer'>
                <p>Muchas gracias por confiar en Wolly.</p>
            </div>
            
        </div>
    )
    

}