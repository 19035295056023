
import { useEffect, useState } from "react";
import moment from "moment";
import "react-dates/initialize";

import { getUploadPhotosData,setAppontment } from "../../api/apiCalls";
import { Modal } from "react-responsive-modal";
import {SingleDatePicker} from "react-dates";
import { useStateValue } from "../../state/context";
import ControlButtons from "../../components/basics/ControlButtons"

import casco from "../../assets/upload-photos/casco.png";
import maletin from "../../assets/upload-photos/maletin.png";
import warningCircle from "../../assets/upload-photos/WarningCircle.svg";
import calendar from "../../assets/calendarIcon.svg";
import phone from "../../assets/upload-photos/phone.png";
import ping from "../../assets/upload-photos/ping.png";
import client from "../../assets/upload-photos/client2.png";
import wolly from "../../assets/upload-photos/wolly-yellow.png";

import "../../styles/uploadPhotosPage.scss"
import "react-dates/lib/css/_datepicker.css";
import "react-responsive-modal/styles.css";


export default function UploadPhotosPage(){

    const [matchData, setMatchData] = useState(false);
    const [open, setOpen] = useState(false);
    const [previousAppointment, setPreviousAppointment] = useState(matchData.appointment != "none" &&  matchData.appointment != undefined  ? moment(matchData.appointment.toString()):null);
    const [showPreviousAppointment, setShowPreviousAppointment] = useState(false);
    const [appointmentDate, setAppointmentDate] = useState(matchData.appointment != "none" &&  matchData.appointment != undefined  ? moment(matchData.appointment.toString()):null);
    const [focusedDatePicker, setFocusedDatePicker] = useState(false);
    const [wollerId, setWollerId] = useState();
    const [jobId, setJobId] = useState();

    const [{user},dispatch] = useStateValue();

    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    
    useEffect(()=> {
        setNewData();
    },[]);

    const setNewData = async () => {
        let url = window.location.href.split("?");
        
        getUploadPhotosData(url[1], url[2]).then(res=>{  // jobId, wollerId
            setWollerId(url[2])
            setJobId(url[1])
            setMatchData(res.result);
            if(res.result.price){
                dispatch({
                    type: "SET_UPLOAD_PHOTOS_PRICE",
                    price: res.result.price
                });
            }
        }).catch(err=>{
            console.error(err);
        })
    }

    const onContinue = () => {
        let url = window.location.href.split("?");
        window.location.replace(`${url[0]}-pre?${url[1]}?${url[2]}`);
    }

    const formatStringToNumberMonth = (stringMonth) => {
        if(stringMonth === "Jan") return "01"
        else if(stringMonth === "Feb") return "02"
        else if(stringMonth === "Mar") return "03"
        else if(stringMonth === "Apr") return "04"
        else if(stringMonth === "May") return "05"
        else if(stringMonth === "Jun") return "06"
        else if(stringMonth === "Jul") return "07"
        else if(stringMonth === "Aug") return "08"
        else if(stringMonth === "Sep") return "09"
        else if(stringMonth === "Oct") return "10"
        else if(stringMonth === "Nov") return "11"
        else if(stringMonth === "Dec") return "12"
        else return ""
    }

   
    const handleChangeAppointment = async ()=>{
        setPreviousAppointment(appointmentDate)
        let result = await setAppontment({jobId:jobId,appointment:new Date(appointmentDate.format()),wollerId:wollerId})
        if(!result){
            console.error(result)
        }else{
            alert("Datos actualizados")
            setNewData()
            setShowPreviousAppointment(true)
        }
    }
    
    const formatDateWhitoutHour = ( date ) => {
        return `${date.substring(8,10)}-${formatStringToNumberMonth(date.substring(4,7))}-${date.substring(11,15)}`
    }

    return(
        <div className="upload-photo-page mainContainer">
            <img src={casco} alt="isotipo" className="upload-photo-main-image" />
            <p className="upload-photo-main-title">servicio wolly</p>
            <p className="upload-photo-main-subtitle">Hola Woller, vamos a iniciar la gestión del servicio que has aceptado</p>
            <div className="upload-photo-main-card">
                <div className="f-row">
                    <p className="upload-photo-main-fixed-price">PRECIO FIJO</p>
                    <p className="upload-photo-main-fixed-gremio">{matchData ? matchData.gremio : ""}</p>
                </div>
                <p className="upload-photo-main-fixed-job-title">{matchData.jobTitle}</p>
                <div className="f-row m-bott-20">
                    <img src={client} alt="isotipo" className="upload-photo-main-card-icon" />
                    <p className="upload-photo-main-card-p">{matchData.clientName}</p>
                </div>
                <div className="f-row m-bott-20">
                    <img src={ping} alt="isotipo" className="upload-photo-main-card-icon" />
                    <p className="upload-photo-main-card-p">{matchData.address}</p>
                </div>
                <div className="f-row m-bott-20">
                    <img src={phone} alt="isotipo" className="upload-photo-main-card-icon" />
                    <p className="upload-photo-main-card-p">{matchData.jobPhone}</p>
                </div>
                <div className="f-row m-bott-20">
                    <img src={maletin} alt="isotipo" className="upload-photo-main-card-icon" />
                    <p className="upload-photo-main-card-p">{matchData.fixedId}</p>
                </div>
                <div className="f-row m-y-20 description">
                    <button className="upload-photo-main-card-description" onClick={onOpenModal}>Ver descripción</button>
                </div>
                <div className="f-row m-top-20 m-bott-20">
                    <p className="upload-photo-main-card-p">Fecha de cita: </p>
                </div>
                
                {matchData.appointment == "none" || !matchData.appointment  ?(<div className="f-row m-top-20 upload-photo-main-card-warning-icon">
                    <img src={warningCircle} alt="isotipo" className="m-x-20 " />
                    <p className="upload-photo-main-card-p">Pendiente de asignar fecha de cita</p>
                </div>)
                :
                (<>
                    <div className="f-row m-top-20 ">
                        
                        <img src={calendar} alt="isotipo" className="m-x-20 upload-photo-main-card-icon " />
                        <p className="upload-photo-main-card-p">{formatDateWhitoutHour(matchData.appointment.toString()) }</p>
                    </div>
                    {showPreviousAppointment && 
                        <div className="f-row m-top-20 upload-photo-old-date">
                            <div>
                                <span className="upload-photo-main-card-p ">Fecha previa: </span>
                                <span className="upload-photo-main-card-p">{formatDateWhitoutHour(previousAppointment.toString()) }</span>
                            </div>
                        </div>
                    }
                    
                </>)}
                
                <div className="f-row m-top-20">
                    <button className="upload-photo-main-card-appointment" onClick={()=>setFocusedDatePicker(!focusedDatePicker)}>{matchData.appointment != "none" ? "Cambiar cita":"Asignar cita"}</button>
                    <div className={focusedDatePicker ? "datePickerContainer active":"datePickerContainer"}>
                        <p className="upload-photo-main-card-p">Selecciona la fecha de cita</p>
                        <SingleDatePicker
                            date={appointmentDate ? appointmentDate:null} // momentPropTypes.momentObj or null
                            onDateChange={date => setAppointmentDate(date)} // PropTypes.func.isRequired
                            focused={focusedDatePicker} // PropTypes.bool
                            id="singleAppointmentDate" // PropTypes.string.isRequired,
                            // onFocusChange={focusedInput => setFocusedDatePicker(focusedDatePicker)} // PropTypes.func.isRequired,
                            minDate={moment()}
                            numberOfMonths={1}
                        />
                        <ControlButtons onCancel={()=>setFocusedDatePicker(false)} onSave={()=>{handleChangeAppointment();setFocusedDatePicker(false)}} className="appointmentControls"  saveDisabled={!appointmentDate} ></ControlButtons>
                    </div>
                </div>
                <div className="f-row m-top-20">
                    <p className="upload-photo-main-card-p assistance">¿Hay alguna incidencia con la cita? <b><a href="tel:+34685181352" >contacta</a></b> con nosotros</p>
                </div>
            </div>
            <p className="upload-photo-main-subtitle"> ¡Recuerda que es imprescindible completar este proceso para dar por finalizado el servicio!</p>
            {matchData.appointment != "none" ?<button className="upload-photo-main-next" onClick={() => onContinue()}>Comenzar</button>:null}
            <img src={wolly} alt="isotipo" className="upload-photo-wolly-image" />
            <Modal open={open} onClose={onCloseModal} center>
                <div className="upload-photo-modal-wrapper">
                    <p className="upload-photo-modal-title">DESCRIPCIÓN:</p>
                    <p>{matchData.description}</p>
                    <p className="upload-photo-modal-title">QUE INCLUYE:</p>
                    {matchData.include && matchData.include.map( includes => (
                        <p>- {includes}</p>
                    ))}
                    <p className="upload-photo-modal-title">QUE NO INCLUYE:</p>
                    {matchData.doesntInclude && matchData.doesntInclude.map( doesntIncludes => (
                        <p>- {doesntIncludes}</p>
                    ))}
                </div>
            </Modal>
        </div>
    )
}